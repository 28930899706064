import "@aws-amplify/ui-react/styles.css";
import React from 'react';

const Contact = () => {
    return (
      <div>
        <h2>Contact Page</h2>
        {/* Add content for the contact page */}
      </div>
    );
  };
  
  export default Contact;