import "@aws-amplify/ui-react/styles.css";
import React from 'react';

const Projects = () => {
    return (
      <div>
        <h2>Projects Page</h2>
        {/* Add content for the projects page */}
      </div>
    );
  };
  
  export default Projects;